import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout, { PreFooter } from '../components/Layout'
import SEO from '../components/seo'
import InfoSection from '../components/InfoSection'

import { BsChevronRight, BsChevronLeft } from 'react-icons/bs'

import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

import PAGE_CONTENTS from '../../content/pages/for-brands.json'

import BRAND_PARTNER_0 from '../img/brand_partners/aqua_vault.png'
import BRAND_PARTNER_1 from '../img/brand_partners/beautystat.png'
import BRAND_PARTNER_2 from '../img/brand_partners/benjilock.png'
import BRAND_PARTNER_3 from '../img/brand_partners/birdie.png'
import BRAND_PARTNER_4 from '../img/brand_partners/blanquil.png'
import BRAND_PARTNER_5 from '../img/brand_partners/blink.png'
import BRAND_PARTNER_6 from '../img/brand_partners/bri_on_brows.png'
import BRAND_PARTNER_7 from '../img/brand_partners/dafni.png'
import BRAND_PARTNER_8 from '../img/brand_partners/dropstop.png'
import BRAND_PARTNER_9 from '../img/brand_partners/keysmart.png'
import BRAND_PARTNER_10 from '../img/brand_partners/limitless_innovations.png'
import BRAND_PARTNER_11 from '../img/brand_partners/mission.png'
import BRAND_PARTNER_12 from '../img/brand_partners/my_magic_carpet.png'
import BRAND_PARTNER_13 from '../img/brand_partners/sola_wave.png'
import BRAND_PARTNER_14 from '../img/brand_partners/spotlight.png'
import BRAND_PARTNER_15 from '../img/brand_partners/wrinkles_schminkles.png'

const BRAND_PARTNERS = [
  BRAND_PARTNER_0,
  BRAND_PARTNER_1,
  BRAND_PARTNER_2,
  BRAND_PARTNER_3,
  BRAND_PARTNER_4,
  BRAND_PARTNER_5,
  BRAND_PARTNER_6,
  BRAND_PARTNER_7,
  BRAND_PARTNER_8,
  BRAND_PARTNER_9,
  BRAND_PARTNER_10,
  BRAND_PARTNER_11,
  BRAND_PARTNER_12,
  BRAND_PARTNER_13,
  BRAND_PARTNER_14,
  BRAND_PARTNER_15,
]

const ForBrands = props => {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title

  const slider = React.useRef(null)

  const settings = {
    arrows: false,
    dots: true,
    fade: true,
    infinite: true,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    appendDots: dots => (
      <div>
        <div className="cp-slider-control">
          <a
            href="#"
            onClick={e => {
              e.preventDefault()

              slider.current.slickPrev()
            }}
            className="cp-arrow-left"
          >
            <BsChevronLeft />
          </a>

          <ul style={{ margin: '0px' }}> {dots} </ul>

          <a
            href="#"
            onClick={e => {
              e.preventDefault()

              slider.current.slickNext()
            }}
            className="cp-arrow-right"
          >
            <BsChevronRight />
          </a>
        </div>
      </div>
    ),
  }

  return (
    <Layout
      location={props.location}
      title={siteTitle}
      HeaderExtension={
        <div className="header_extension no_bg press aligncenter">
          <div className="bg">
            <div className="container">
              <div className="columns post-single ui-grid">
                <div className="column is-8">
                  <Fade bottom duration={1000} delay={125} distance={'25%'}>
                    <h2>Bigger reach, better content</h2>
                  </Fade>
                </div>
              </div>

              <Fade bottom duration={1000} delay={250} distance={'25%'}>
                <p>And a hell of a lot of sales</p>
              </Fade>

              <Fade bottom duration={1000} delay={375} distance={'25%'}>
                <Link to="/contact" className="button">
                  Get Knocking
                </Link>
              </Fade>
            </div>
          </div>
        </div>
      }
    >
      <SEO
        title="For brands"
        keywords={['Brand awareness', 'Brand reach', 'Increase sales']}
        description="Learn about how our unique model helps our partners achieve exponential brand awareness and brand reach, and increase sales"
      />

      <div className="page-content pages-sf">
        <div className="container">
          {(PAGE_CONTENTS.infoSections || []).map((infoSection, i) => (
            <InfoSection key={i} {...infoSection} reverse={i % 2 !== 0} />
          ))}
        </div>
      </div>

      <div className="page-content pages-index pages-about pages-shows">
        <div className="section-desc" style={{ background: 'transparent' }}>
          <div className="container">
            {' '}
            <div className="columns is-vcentered is-multiline is-mobile cp-logos">
              {BRAND_PARTNERS.slice(0, 8).map((image, i) => (
                <div
                  className="column is-6-mobile is-4-tablet is-narrow-desktop is-narrow-widescreen is-narrow-fullhd"
                  key={`mp-${i}`}
                >
                  <Fade
                    effect="fadeInUp"
                    effectOut="fadeOutLeft"
                    delay={62.5 * i}
                  >
                    <img src={image} />
                  </Fade>
                </div>
              ))}
            </div>
            <div className="columns is-vcentered is-multiline is-mobile cp-logos">
              {BRAND_PARTNERS.slice(8).map((image, i) => (
                <div
                  className="column is-6-mobile is-4-tablet is-narrow-desktop is-narrow-widescreen is-narrow-fullhd"
                  key={`mp-${i}`}
                >
                  <Fade
                    effect="fadeInUp"
                    effectOut="fadeOutLeft"
                    delay={62.5 * i}
                  >
                    <img src={image} />
                  </Fade>
                </div>
              ))}
            </div>
            <div className="columns is-vcentered is-multiline is-mobile cp-logos-thin">
              {BRAND_PARTNERS.map((image, i) => (
                <div
                  className="column is-6-mobile is-4-tablet is-narrow-desktop is-narrow-widescreen is-narrow-fullhd"
                  key={`mp-${i}`}
                >
                  <Fade
                    effect="fadeInUp"
                    effectOut="fadeOutLeft"
                    delay={62.5 * i}
                  >
                    <img src={image} />
                  </Fade>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="page-content pages-sf">
        <div className="container">
          <Slider className="cp-slider" {...settings} ref={slider}>
            {(PAGE_CONTENTS.testimonials || []).map((slide, i) => (
              <div key={`slide-${i}`}>
                <p className="cp-text">{slide.text}</p>

                <div className="cp-prs">
                  {slide.image && <img src={slide.image} />}

                  <p className="cp-name">{slide.name}</p>
                  <p className="cp-company">{slide.company}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <PreFooter
        title="It all starts with your product and a quick pitch"
        linkText="Get started"
        linkTo="/contact"
      />
    </Layout>
  )
}

export default ForBrands

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { eq: "press" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
